var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "form-retur-gudang",
      "title": "Form Retur Gudang",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "disabled": !_vm.gudang_id,
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Gudang Tujuan Retur"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "reduce": function reduce(option) {
        return option.value;
      },
      "options": _vm.gudangOptions
    },
    model: {
      value: _vm.gudang_id,
      callback: function callback($$v) {
        _vm.gudang_id = $$v;
      },
      expression: "gudang_id"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }